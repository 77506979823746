<template>
  <Head>
    <title>Страница не найдена {{ $titleEnd }}</title>
    <meta property="og:title" :content="'Страница не найдена ' + $titleEnd" />
  </Head>
  <main class="main mb-lg-0">
    <div class="container">
      <div
        class="not-found d-flex justify-content-center align-items-center flex-column"
      >
        <div class="particle" />
        <div class="inner">
          <h1 class="h2">Страница не&nbsp;найдена</h1>
          <p>
            Возможно, запрашиваемая Вами страница не&nbsp;существует.
            Пожалуйста, вернитесь на&nbsp;главную страницу.
          </p>
          <p>
            <router-link class="btn btn-default" :to="{ name: 'Home' }">
              Вернуться на главную
            </router-link>
          </p>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { Head } from "@vueuse/head";
export default {
  components: {
    Head,
  },
};
</script>

<style scoped>
.particle {
  position: absolute;
  top: -1px;
  left: 50%;
  z-index: -1;
  width: 981px;
  height: 685px;
  margin-left: -490px;
  background: url(~@i/bg-not-found.svg) 50% 0 no-repeat;
}

.not-found {
  position: relative;
  min-height: 600px;
  margin-top: -54px;
  font-size: 20px;
}

.inner {
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
}

.btn {
  margin-top: 20px;
}
@media (max-width: 991px) {
  .not-found {
    min-height: auto;
    margin-top: 0;
  }

  .particle {
    display: none;
  }
}
</style>
